import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ImageParams } from '../../../api/furniture';
import { RootState } from '../../../App/rootReducer';
import { loginUser } from 'features/user/userSlice';
import {
  fetchFurnitures,
  createFurniture,
  updateFurniture,
  deleteFurniture,
  fetchSelectedFurnitures,
} from '../furnitureSlice';
import { fetchHouseholds } from '../../../features/household/householdSlice';
import { FormData } from 'components/organisms/FurnitureModal';
import * as Sentry from '@sentry/browser';
import Furniture from '../../../components/pages/Furniture';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default function FurnitureWithRedux(props: Props) {
  const dispatch = useDispatch<any>();

  const user = useSelector((state: RootState) => state.user.currentUser);

  // TODO: as any良くない
  const furnitures = useSelector(
    (state: RootState) => state.furniture.furnitures
  ) as any;

  const households = useSelector(
    (state: RootState) => state.household.households
  ) as any;

  const loading = useSelector((state: RootState) => state.furniture.loading);

  const slideOpen = useSelector(
    (state: RootState) => state.user.slideOpen
  ) as any;
  const designApprovalCompletion = useSelector(
    (state: RootState) => state.furniture.designApprovalCompletion
  ) as boolean;

  useEffect(() => {
    async function f() {
      try {
        await dispatch(fetchSelectedFurnitures(null, true));
        await dispatch(fetchHouseholds());
        dispatch(loginUser());
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  }, [dispatch]);

  const onSelectedFetchFurnitures = async (
    householdId: number | null,
    isDefaultHousehold: boolean | null
  ) => {
    try {
      await dispatch(
        fetchSelectedFurnitures(Number(householdId), isDefaultHousehold)
      );
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const onSubmitFurniture = async (
    params: FormData,
    files: object[],
    householdId: number | null
  ) => {
    try {
      const formatted_files: ImageParams[] = [];

      // TODO: anyを修正
      files.forEach((file: any) => {
        formatted_files.push({
          name: file.file.name,
          url: file.file.type,
          image: file.file,
        });
      });

      const name = `${params.name}`;
      const width = `${params.width}`;
      const depth = `${params.depth}`;
      const height = `${params.height}`;
      const place = `${params.place}`;
      const purchase = `${params.purchase}`;
      const memo = `${params.memo}`;
      await dispatch(
        createFurniture(
          name,
          width,
          depth,
          height,
          place,
          purchase,
          memo,
          formatted_files,
          householdId
        )
      );
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const onUpdateFurniture = async (
    params: FormData,
    files: object[],
    householdId: number | null
  ) => {
    try {
      const formattedFiles: ImageParams[] = [];

      // TODO: anyを修正
      files.forEach((file: any) => {
        formattedFiles.push({
          name: file.file ? file.file.name : '',
          url: file.file ? file.file.type : '',
          image: file.file ? file.file : '',
          id: file.id,
        });
      });

      const name = `${params.name}`;
      const width = `${params.width}`;
      const depth = `${params.depth}`;
      const height = `${params.height}`;
      const place = `${params.place}`;
      const purchase = `${params.purchase}`;
      const memo = `${params.memo}`;
      await dispatch(
        updateFurniture(
          params.furnitureId!,
          name,
          width,
          depth,
          height,
          place,
          purchase,
          memo,
          formattedFiles,
          householdId
        )
      );
    } catch (error) {
      // Sentry.captureException(error);
      throw error;
    }
  };

  const onDeleteFurniture = (
    furnitureId: number,
    householdId: number | null,
    isDefaultHousehold: boolean | null
  ) => {
    async function f() {
      try {
        await dispatch(deleteFurniture(furnitureId));
        await dispatch(
          fetchSelectedFurnitures(householdId, isDefaultHousehold)
        );
      } catch (error) {
        // Sentry.captureException(error);
      }
    }
    f();
  };

  return (
    <>
      <Furniture
        furnitures={furnitures}
        households={households}
        onDelete={onDeleteFurniture}
        onSubmitFurniture={onSubmitFurniture}
        onUpdateFurniture={onUpdateFurniture}
        designApprovalCompletion={designApprovalCompletion}
        loading={loading}
        slideOpen={slideOpen}
        handedOver4monthsAgo={user!.handed_over_4months_ago}
        handleFetch={onSelectedFetchFurnitures}
      />
    </>
  );
}
